import React, { useState, Fragment, Component, useCallback } from 'react'
import Shimmer from "react-shimmer-effect";
import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button, Modal, Spinner, Input
} from "reactstrap"
import Select from "react-select"
import { ConfirmAlert } from '../../../../helpers/ui/alert'
import { Preview } from '../../../../helpers/ui/FileInput'

import { Link, useHistory, useParams, Redirect } from "react-router-dom"
import { withRouter } from "react-router";
import moment from "moment"
import 'moment/locale/id'

import apiResponse from '../../../../services/apiResponse'
import { FileInput, generateFormData } from '../../../../helpers/ui/FileInput'
import { AvForm, AvGroup, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import ReviewBloc from '../../DaftarSurat/Review/Blocs/ReviewBloc'
import PreviewLetterOutPdf  from '../../../../helpers/ui/outbox/PreviewLetterOutPdf'
import DetailSuratMasuk from './Components/DetailSuratMasuk'
import logoBsre from "../../../../assets/images/logo-bsre.png"
import ModalCannotSign from "../../../../components/Outbox/Sign/ModalCannotSign"
import LetterTimeline from '../../../../components/Outbox/LetterTimeline'
import ModalSendReview from '../../../../components/Outbox/ModalSendReview'

var reformatOptions = function(raw, label, value) {
    return raw.map(function(data) {
      var newObj = {};
      newObj["label"] = data[label];
      newObj["value"] = data[value];

      return newObj;
    });
};
var reformatOptionsDelegation = function(raw, label, value, nip) {
    return raw.map(function(data) {
      var newObj = {};
      newObj["label"] = data[label];
      newObj["nip"] = data[nip];
      newObj["value"] = data[value];

      return newObj;
    });
};
class DetailView extends Component {

    reviewBloc = new ReviewBloc()

    constructor(props) {
        super(props)
        this.state = {
            showReferenceLetter: false,
            doDrafting: false,
            loading: true,
            loadingInfo : "Tunggu beberapa saat, sedang menyelaraskan dokumen",
            loadingTitle: "Memproses Dokumen...",
            failedInfo: "Gagal Menyimpan Data",
            successInfo : "Data berhasil disimpan",
            document_url:"",
            confirmInfo:"",
            uuid:"",
            reviewer_nip:"",
            reviewer_position:"",
            random : 0,
            show_sign : false,
            can_sign : false,
            cannot_sign_message : null,
            modalSendReview: false,
            modalSendBack : false,
            modalSignLetter : false,
            modalBookingNomor : false,
            response_note:"",
            statusproses:0,
            passphrase:"",
            chooseReviewDirect: false,
            chooseReviewDelegation: false,
            modalNotifSign: false,
            jmlReceiver : 0,
            jmlCopy : 0,
            delegationOption: [],
            signType: 0,
            signerType: 1,
            unitPenomoran:"",
            thisQrcode:"",
            reviewType:1,
            logsurat: [],
            iseselon1 : false,
            drafter_unit:null,
            eselonsatu_unit:null,
            chooseub:false,
            modalAddAttachment:false,
            redirecturl: '/outbox/daftarsurat.html',
            deleteparticipant: 0,
            currentRole : localStorage.getItem('currentRoleIdreal'),

            // Pilihan Penerima Revisi
            isDelegateRevision: false,
            letterRevisionReceiverList: [],
            hideWarningOtherRevisionReceiver: true,
            sendRevisionToPositionUuid: null,

            hideWarningEmptyNote: true,
            openprev: false,
            current_signer: null,

            autoReloadTimeout: 20_000,
        }
        this.handleChangeNote = this.handleChangeNote.bind(this);
        this.handleChangePassphrase = this.handleChangePassphrase.bind(this);
    }
    handleOnDodrafting = () => {
        this.reviewBloc.fetchMarkDraftRegenerate({ qrcode: this.state.detail.letter_out_qrcode })

        const myWindowDoc = window.open(this.state.detail.letter_out_filepath,"_blank",);
        const timer = setInterval(() => {
            if(myWindowDoc.closed) {
                clearInterval(timer);
                this.setState({
                    random : Math.floor((Math.random() * 100) + 1),
                    loading : true,
                    openprev: false,
                })

                this.reviewBloc.fetchPdfDraftRegenerate({ qrcode: this.state.detail.letter_out_qrcode })
            }
        }, 1000);
    };

    handleOnReloadRegenerate = () => {
        this.setState({ loading: true, autoReloadTimeout: 120_000 })
        const queryParams = new URLSearchParams(this.props.location.search)
        this.reviewBloc.fetchDetail({ uuid: queryParams.get('uuid'), isweb: 1 })
    }

    handleOnAutoReloadRegenerate = () => {
        this.setState({ showPreviewLoading: true, autoReloadTimeout: 120_000 })
        const queryParams = new URLSearchParams(this.props.location.search)
        this.reviewBloc.fetchDetail({ uuid: queryParams.get('uuid'), isweb: 1 })
    }

    handleRefreshIFrame = () => {
        //alert("show");
    }

    handleShowReferenceLetter = () => {
        //console.log(option);
        this.setState ({
            showReferenceLetter : true
        })
    }
    hideLoad = () => {
        //alert("close frame");
        this.setState({
          loading: false
        });
    };

    handleChooseReviewDirect = () => {
        this.setState ({
            chooseReviewDirect: true,
            chooseReviewDelegation: false,
            reviewType: 1,
            reviewer_nip: this.state.atasan.position_employee_nip,
            reviewer_position: this.state.atasan.position_uuid,
        })
    }

    handleChooseReviewDelegation = () => {
        this.setState ({
            chooseReviewDirect: false,
            chooseReviewDelegation: true,
            reviewType: 2
        })
    }

    handleSetReviewer = (option) => {
        //console.log(option);
        let selected = option.value
        //alert(selected)
        this.setState ({
            reviewer_nip: option.nip,
            reviewer_position: selected,
        })
    }
    handleSendReview = () => {
        this.setState ({
            modalSendReview : true,
            chooseReviewDirect: true,
            chooseReviewDelegation: false
        })
    }
    handleSendLetter = () => {
        //e.preventDefault()
        //alert(this.state.submitStatus)
        //return;
        this.setState({
            dataForm: {
                uuid: this.state.uuid,
                qrcode: this.state.thisQrcode,
                reviewer_nip : this.state.reviewer_nip,
                reviewer_position : this.state.reviewer_position,
                reviewer_type : this.state.reviewType,
                note: this.state.review_note || null,
            },
            confirmInfo: "Apakah anda yakin akan mengirim surat ini?",
            loadingInfo: "Tunggu beberapa saat",
            loadingTitle: "Mengirim data...",
            confirm: true,
            statusproses:2,
            modalSendReview : false
        })
        //console.log(this.state.dataForm)
    }
    handleSendBack = () => {
        this.setState ({
            modalSendBack : true
        })
    }
    handleChangeNote(event) {
        const value = event.target.value

        this.setState({ response_note: value });
        this.hideWarningEmptyNote(!! value)
    }

    hideWarningEmptyNote = (hidden = true) => {
        this.setState({ hideWarningEmptyNote: hidden })
    }

    handleRevisionReceiverTypeChange = (e) => {
        const { value } = e.target
        const isDelegateRevision = 'delegasi' === value

        this.setState({ isDelegateRevision })

        if (! isDelegateRevision) this.setDrafterRevisionReceiver()
        else this.setDrafterRevisionReceiver(null)
    }

    setDrafterRevisionReceiver = (value = undefined) => {
        this.setState({
            sendRevisionToPositionUuid: value !== undefined
              ? value
              : this.state.drafter?.letter_out_process_uuid,
        })
    }

    hideWarningOtherRevisionReceiver = (hidden = true) => {
        this.setState({ hideWarningOtherRevisionReceiver: hidden })
    }

    handleOtherRevisionReceiverChange = ({ value }) => {
        if (! value) {
            this.hideWarningOtherRevisionReceiver(false)
            this.setState({ sendRevisionToPositionUuid: null })
        }

        this.hideWarningOtherRevisionReceiver()
        this.setState({ sendRevisionToPositionUuid: value })
    }

    validate = () => {
        let isValid = true

        if (! this.state.sendRevisionToPositionUuid) {
            this.hideWarningOtherRevisionReceiver(false)
            isValid = false
        }

        if (! this.state.response_note) {
            this.hideWarningEmptyNote(false)
            isValid = false
        }

        return isValid
    }

    handleReturnLetter = () => {
        //e.preventDefault()
        //alert(this.state.submitStatus)
        //return;

        if (! this.validate()) {
            return
        }

        this.setState({
            dataForm: {
                uuid: this.state.uuid,
                drafter_uuid: this.state.sendRevisionToPositionUuid,
                //request_nip : this.state.reviewer_nip,
                //request_position : this.state.reviewer_nip,
                response_note : this.state.response_note
            },
            confirmInfo: <Fragment><span>Apakah anda yakin akan mengembalikan surat ini?</span></Fragment>,
            loadingInfo: "Tunggu beberapa saat",
            loadingTitle: "Mengirim data...",
            confirm: true,
            modalSendBack: false,
            statusproses: 9,
        })
        //console.log(this.state.dataForm)
    }

    handleOnSignClick = () => {
        if (this.state.can_sign) {
            this.handleShowModalSign()
        } else {
            this.handleShowCannotSignMessage()
        }
    }

    handleShowModalSign = () => {
        this.setState ({
            modalSignLetter : true
        })

    }

    handleShowCannotSignMessage = () => {
        this.setState ({ showModalCannotSign : true })
    }

    handleHideCannotSignMessage = () => {
        this.setState ({ showModalCannotSign : false })
    }

    handleChangePassphrase(event) {
        this.setState({passphrase: event.target.value});
    }

    handleSignNoDS  = () => {
        this.setState ({
            modalNotifSign : false,
            failedInfo:"Proses Tandatangan belum berhasil",
            failed: true
        })
    }
    handleChooseSigner = (x) => {

        this.setState({
            signerType:x,
            chooseub:(x == 3 ? true:false)
        })

    }
    handleSignLetter = () => {
        //e.preventDefault()
        //alert(this.state.submitStatus)
        //return;
        this.setState({
            dataForm: {
                uuid: this.state.uuid,
                passphrase : this.state.passphrase,
                signerType: this.state.signerType,
                eselonsatu: this.state.eselonsatu_unit
            },
            confirmInfo: <Fragment><span>Apakah anda yakin akan menandatangani surat ini ?</span> </Fragment>,
            loadingInfo: "Tunggu beberapa saat",
            loadingTitle: "Mengirim data...",
            confirm: true,
            modalSignLetter:false,
            statusproses:4
        })
        //console.log(this.state.dataForm)
    }

    handleSignNonDs = () => {
        //e.preventDefault()
        //alert(this.state.submitStatus)
        //return;
        this.setState({
            dataForm: {
                uuid: this.state.uuid
            },
            confirmInfo: "Apakah anda yakin akan menandatangani surat ini?",
            loadingInfo: "Tunggu beberapa saat",
            loadingTitle: "Mengirim data...",
            confirm: true,
            statusproses:6
        })
        //console.log(this.state.dataForm)
    }

    handleSetEselon1 = (option) => {
        //console.log(option);
        let selected = option.value
        //alert(selected)
        this.setState ({
            eselonsatu_unit: selected,
        })
    }

    handleTambahAttachemnt= () => {
        this.setState ({
            modalAddAttachment : true
        })
    }
    handlerOnSubmit = (e, values) => {
        e.preventDefault()
        //alert(this.state.submitStatus)
        //return;
        this.setState({
            dataForm: {
                qrcode: this.state.thisQrcode,
                ...values
            },
            confirm: true,
            statusproses:8,
            modalAddAttachment:false,
        })
    }

    deleteFileHandler = (file_id) => {
        this.setState({
            dataForm: {
                uuid: file_id
            },
            confirmInfo: "Apakah anda yakin akan menghapus lampiran ini?",
            loadingInfo: "Tunggu beberapa saat",
            loadingTitle: "Mengirim data...",
            confirm: true,
            statusproses:81
        })
    }

    handleCancelPartisipan = (nip) => {
        //alert("sini")
        //alert(nip)
        this.setState({
            dataForm: {
                nip: nip,
                qrcode: this.state.thisQrcode
            },
            confirmInfo: <Fragment><span>Apakah anda yakin akan membatalkan pegawai ini ?</span> </Fragment>,
            loadingInfo: "Tunggu beberapa saat",
            loadingTitle: "Mengirim data...",
            statusproses: 99,
            //thisQrcode:this.state.detail.letter_out_qrcode,
            confirm: true
        })
    }

    submitData = async () => {
        console.log(this.state.dataForm)
        const formData = generateFormData(this.state.dataForm, {field: 'document', name: 'suratkeluar/attachment'}) // 'document' disini adalah nama field FileInput. Jika ada lebih dari 1 file input, maka gunakan array. conth: ['document', 'foto']
        //console.log(formData)
        if(this.state.statusproses == 2){
            await this.reviewBloc.fetchSendReview(formData)
        }
        if(this.state.statusproses == 9){
            await this.reviewBloc.fetchReturnReview(formData)
        }
        if(this.state.statusproses == 4){
            await this.reviewBloc.fetchSignLetter(formData)
        }
        if(this.state.statusproses == 6){
            await this.reviewBloc.signNonDsLetterChannel(formData)
        }
        if(this.state.statusproses == 3){
            this.setState({
                loading: false,
                failed: true
            });
            // this.setState({
            //     loading: false
            // }, ()=> {
            //     this.setState({
            //         failed: true
            //     })
            // });
        }
        if(this.state.statusproses == 8){
            await this.reviewBloc.fetchAddAttachment(formData)
        }
        if(this.state.statusproses == 81){
            await this.reviewBloc.fetchDeleteAttachment(formData)
        }

        if(this.state.statusproses == 99){
            await this.reviewBloc.fetchCancelParticipant(formData)
        }
    }

    confirmResponse = (response) => {
        let forState = {}
        switch(response) {
            case 'cancel':
                forState = {
                    confirm: false
                }
                break;
            case 'confirm':
                forState = {
                    loading: true,
                    confirm: false
                }
                this.submitData()
                break;
            case 'success':
                forState = {
                    success: false,
                    redirect: true
                }
                break;
             case 'failed':
                forState = {
                    failed: false
                }
                break;
            default:
        }
        this.setState(forState)
    }

    componentDidMount() {
        this.reviewBloc.signLetterChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    (result.data.status ?
                        this.setState({
                            loading: false,
                            success: true,
                            successInfo:result.data.message
                        })
                        :
                        this.setState({
                            loading: false,
                            modalNotifSign: true,
                            failedInfo:result.data.message
                        })
                    )

                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })
        this.reviewBloc.signNonDsLetterChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    (result.data.status ?
                        this.setState({
                            loading: false,
                            success: true
                        })
                        :
                        this.setState({
                            loading: false,
                            modalNotifSign: true,
                            failedInfo:result.data.message
                        })
                    )

                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })
        this.reviewBloc.sendLetterChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                        failedInfo: "Gagal Mengirim ke Atasan"
                    });
                    break
                default:
                    break
            }
        })

        this.reviewBloc.returnLetterChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    this.setState({
                        loading: false,
                        success: true,
                    });
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.reviewBloc.addAttachmentChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const queryParams = new URLSearchParams(this.props.location.search)
                    var url = '/outbox/daftarsurat.html?review&uuid='+queryParams.get('uuid');
                    this.setState({
                        loading: false,
                        //redirect2: true,
                        //urlredirect2: url
                    });
                    window.location.reload();
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.reviewBloc.deleteAttachmentChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const queryParams = new URLSearchParams(this.props.location.search)
                    var url = '/outbox/daftarsurat.html?review&uuid='+queryParams.get('uuid');
                    this.setState({
                        loading: false,
                        //redirect2: true,
                        //urlredirect2: url
                    });
                    window.location.reload();
                    //const queryParams = new URLSearchParams(this.props.location.search)
                    //this.reviewBloc.fetchDetail({uuid: queryParams.get('uuid'),isweb: 1 })
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.reviewBloc.cancelParticipantChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    const queryParams = new URLSearchParams(this.props.location.search)
                    var url = '/outbox/daftarsurat.html?review&uuid='+queryParams.get('uuid');
                    this.setState({
                        loading: false,
                        //redirect2: true,
                        //urlredirect2: url
                    });
                    //this.reviewBloc.fetchDetail({uuid: queryParams.get('uuid'),isweb: 1 })
                    window.location.reload();
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loading: false,
                        failed: true,
                    });
                    break
                default:
                    break
            }
        })

        this.reviewBloc.rollbackLetterChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    // this.setState({
                    //     loading: false,
                    //     success: true,
                    // });
                    break
                case apiResponse.ERROR:
                    // this.setState({
                    //     loading: false,
                    //     failed: true,
                    //     failedInfo: "Gagal Mengirim ke Atasan"
                    // });
                    break
                default:
                    break
            }
        })

        this.reviewBloc.detailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        //alert(response.letter_out_process.letter_out_process_status_review)
                        if(response.letter_out_process.letter_out_process_status_review >  1){
                            this.setState({
                                redirect: true,
                                redirecturl: '/outbox/daftarsurat.html?detail&qrcode='+response.letter_out_process.letter_out_process_qrcode
                            })
                        }
                        this.setState({
                            letter_process: response.letter_out_process,
                            detail: response.letter_out,
                            current_signer: response.current_signer,
                            signers: response.signers,
                            receiver: response.receiver,
                            participant_ext: response?.participant_ext || [],
                            attachment: response.attachment,
                            refQrcode : response.letter_out.letter_out_ref_qrcode,
                            thisQrcode : response.letter_out.letter_out_qrcode,
                            atasan: response.reviewer,
                            atas_nama: response.atas_nama,
                            tembusan: response.tembusan,
                            delegation : response.delegation,
                            delegationOption: reformatOptionsDelegation(
                                response.delegation,
                                'position_name_text',
                                'position_uuid',
                                'position_employee_nip'
                            ),
                            IurlDoc : response.document_url + "/:w:/r/_layouts/15/Doc.aspx?sourcedoc={" +  response.letter_out.letter_out_sharepoint_doc_id + "}&action=embedview",
                            IurlDocEdit : response.document_url + "/:w:/r/_layouts/15/Doc.aspx?sourcedoc={" +  response.letter_out.letter_out_sharepoint_doc_id + "}&action=default",
                            uuid:response.letter_out_process.letter_out_process_uuid,
                            reviewer_nip: (response.reviewer ? response.reviewer.position_employee_nip : ""),
                            reviewer_position: (response.reviewer ? response.reviewer.position_uuid : ""),
                            show_sign : response.show_sign,
                            can_sign : response.can_sign,
                            cannot_sign_message : response.cannot_sign_message,
                            jmlReceiver : response?.receiver?.length + response?.participant_ext?.length,
                            jmlCopy : response.tembusan.length,
                            signType : response.letter_type_sign_type,
                            logsurat : response.logs,
                            drafter : response.drafter,
                            iseselon2 : response.iseselon2,
                            eselonsatuOption: reformatOptions(
                                response.uniteselon1,
                                'position_name',
                                'position_uuid'
                            ),
                            deleteparticipant : (this.state.currentRole == "1" && response.letter_out.letter_out_type == 6 ? 1 : 0),

                            sendRevisionToPositionUuid: response.drafter?.letter_out_process_uuid,
                            letterRevisionReceiverList: reformatOptions(
                              response?.other_revision_receivers || [],
                              'position_name_text',
                              'letter_out_process_uuid',
                            ),
                            loading: false,
                            showPreviewLoading: false,
                            openprev: true
                        })
                    }
                    break
                case apiResponse.ERROR:

                    break
                default:
                    break
            }
        })

        this.reviewBloc.pdfDraftRegenerateChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                case apiResponse.ERROR:
                    this.setState((prev) => ({
                        loading: false,
                        detail: {
                            ...prev.detail,
                            letter_out_draftpdf_need_regen: true,
                        },
                    }));

                    break
                default:
                    break
            }
        })

        const queryParams = new URLSearchParams(this.props.location.search)

        if (queryParams.get('uuid')) {
            this.reviewBloc.fetchDetail({ uuid: queryParams.get('uuid'), isweb: 1, reset: 1 })
        }
    }


    render() {
        var isdelete = this.state.deleteparticipant

        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card className="">
                                <CardBody>
                                    <CardTitle>Review Surat Keluar</CardTitle>
                                    <CardSubtitle className="mb-3">
                                    Informasi & Pratinjau Surat Keluar
                                    </CardSubtitle>
                                    <hr />
                                    <Row className="mb-3">
                                        <Col md="5">
                                            <Row className="mb-3 mt-2">
                                                <label className="col-md-4">
                                                    Kode Surat
                                                </label>
                                                <Col md="8">
                                                {
                                                    this.state.detail ?
                                                        <>{this.state.detail.letter_out_qrcode !== "" ? this.state.detail.letter_out_qrcode : '-'}</>
                                                    :
                                                        <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                                }
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Jenis Surat
                                                </label>
                                                <Col md="8">
                                                    {
                                                        this.state.detail ?
                                                            <>{this.state.detail.letter_type_name !== "" ? this.state.detail.letter_type_name : '-'}</>
                                                        :
                                                            <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                                    }
                                                </Col>
                                            </Row>
                                            {
                                                this.state.detail ?
                                                    this.state.detail.letter_out_ref_qrcode !== "" ?
                                                        <Row className="mb-3 mt-2">
                                                            <label className="col-md-4">
                                                                Ref Surat Masuk
                                                            </label>
                                                            <Col md="8">
                                                            <button type="button" class="btn-sm btn-rounded waves-effect waves-light btn btn-info" onClick={() => this.handleShowReferenceLetter()}><i class="uil-eye"></i> {this.state.detail.letter_out_ref_agenda}</button>
                                                            </Col>
                                                        </Row>
                                                    : null
                                                :
                                                null
                                            }
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Nomor Surat
                                                </label>
                                                <Col md="8">
                                                    {
                                                        this.state.detail ?
                                                            <>{this.state.detail.letter_out_number !== null ? this.state.detail.letter_out_number : <span class="badge bg-soft-dark font-size-14">Belum Penomoran</span>}</>
                                                        : <Shimmer><div style={{width: 68, height: 15}}></div></Shimmer>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Tanggal Surat
                                                </label>
                                                <Col md="8">
                                                    {
                                                        this.state.detail ?
                                                            <>{this.state.detail.letter_out_date !== null ?  this.state.detail.letter_out_date_text : '-'}</>
                                                        : <Shimmer><div style={{width: '40%', height: 15}}></div></Shimmer>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Sifat Surat
                                                </label>
                                                <Col md="8">
                                                    {
                                                        this.state.detail ?
                                                            <>{this.state.detail.priority_name !== "" ? this.state.detail.priority_name : '-'}</>
                                                        : <Shimmer><div style={{width: '40%', height: 15}}></div></Shimmer>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Perihal
                                                </label>
                                                <Col md="8">
                                                    {
                                                        this.state.detail ?
                                                            <>{this.state.detail.letter_out_title !== "" ? this.state.detail.letter_out_title : '-'}</>
                                                        :
                                                            <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Penandatangan
                                                </label>
                                                <Col md="8">
                                                    {Array.isArray(this.state.signers) ? (
                                                        this.state.signers.length <= 1 ? (
                                                            this.state.signers.map(({ signer_position_text }) => signer_position_text)
                                                        ) : (
                                                            <ul>
                                                                {this.state.signers.map(({ signer_position_text }) => <li>{signer_position_text}</li>)}
                                                            </ul>
                                                        )
                                                    ) : (
                                                        <Shimmer>
                                                            <div style={{ width: '80%', height: 15 }}></div>
                                                        </Shimmer>
                                                    )}
                                                </Col>
                                            </Row>
                                            {this.state.receiver ?
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    {this.state.detail.letter_out_type == 6 ? "Partisipan" : "Penerima"}
                                                </label>
                                                <Col md="8">
                                                    {this.state.jmlReceiver > 1 ? (
                                                        <ul>
                                                            {this.state.receiver.map((value, i) => (
                                                                <li>
                                                                    {value.employee_name && `${value.employee_name}, `}
                                                                    {value.receiver_name}

                                                                    {isdelete ? (
                                                                        <Button
                                                                            className="btn btn-sm btn-danger"
                                                                            id={`detail-${value.receiver_id}`}
                                                                            onClick={() => this.handleCancelPartisipan(value.employee_nip)}
                                                                        >
                                                                            <i className="uil-times"></i>
                                                                        </Button>
                                                                    ) : null}
                                                                </li>
                                                            ))}

                                                            {this.state.participant_ext.map(value => (
                                                                <li>
                                                                    {value.letter_participant_ext_name && `${value.letter_participant_ext_name}, `}
                                                                    {value.letter_participant_ext_position}
                                                                    {' '}
                                                                    (Eksternal)
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    ) : (
                                                        this.state.receiver.map((value, i) => (
                                                            <span>
                                                                {value.employee_name && `${value.employee_name}, `}
                                                                {value.receiver_name}

                                                                {isdelete ? (
                                                                    <Button
                                                                        className="btn btn-sm btn-danger"
                                                                        id={`detail-${value.receiver_id}`}
                                                                        onClick={() => this.handleCancelPartisipan(value.employee_nip)}
                                                                    >
                                                                        <i className="uil-times"></i>
                                                                    </Button>
                                                                ) : null}
                                                            </span>
                                                        ))
                                                    )}
                                                </Col>
                                            </Row>
                                            : null
                                            }
                                            {this.state.tembusan ?
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Tembusan
                                                </label>
                                                <Col md="8">
                                                    {this.state.jmlCopy > 1 ?
                                                        <ul >
                                                        {
                                                            this.state.tembusan.map(function (value, i) {
                                                            return  <li >
                                                                        {value.receiver_name}
                                                                    </li>
                                                            })
                                                        } </ul>
                                                        :
                                                        this.state.tembusan.map(function (value, i) {
                                                        return  <span>{value.receiver_name}</span>
                                                        })
                                                    }
                                                </Col>
                                            </Row>
                                             : null
                                            }
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    File Lampiran
                                                </label>
                                                <Col md="8">
                                                    { this.state.attachment ?
                                                        <Preview
                                                            value={this.state.attachment}
                                                            fileName='attachment_filename'
                                                            filePath='attachment_filepath'
                                                            fileMimeType='attachment_mime'
                                                            fileSource='attachment_source'
                                                            fileDeleted={true}
                                                            fileId='attachment_id'
                                                            col='4'
                                                            deleteFile={this.deleteFileHandler}
                                                        /> : <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                                    }
                                                </Col>
                                                <label className="col-md-4">

                                                </label>
                                                <label className="col-md-8">
                                                    <Button color="success"  className="waves-effect btn-sm" type="button" onClick={() => this.handleTambahAttachemnt()}>
                                                        <i className="uil-plus"></i> tambah lampiran
                                                    </Button>
                                                </label>
                                            </Row>
                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Status
                                                </label>
                                                <Col md="8">
                                                    {
                                                        this.state.letter_process ?
                                                            <span class="badge bg-soft-dark font-size-14">{this.state.letter_process.action_text}</span>
                                                        :
                                                            <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                                    }

                                                </Col>
                                            </Row>

                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Permintaan Review dari
                                                </label>
                                                <Col md="8">
                                                    {
                                                        this.state.letter_process ?
                                                            <span>{this.state.letter_process.request_name + ' - ' + this.state.letter_process.request_position }
                                                            </span>
                                                        :
                                                            <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                                    }

                                                </Col>
                                            </Row>

                                            <Row className="mb-3">
                                                <label className="col-md-4">
                                                    Catatan
                                                </label>
                                                <Col md="8">
                                                    {this.state.letter_process
                                                        ? <span>{this.state.letter_process.letter_out_process_review_note || '-'}</span>
                                                        : <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                                    }
                                                </Col>
                                            </Row>

                                            {! this.state.current_signer || this.state.current_signer.signer_order === 1 ? (
                                                <Row className="mb-3">
                                                    <Col md={{ size: 8, offset: 4 }}>
                                                        <Button
                                                            color="success"
                                                            className="waves-effect btn-sm"
                                                            type="button"
                                                            onClick={() => this.handleOnDodrafting()}
                                                        >
                                                            <i className="uil-pen"></i> Ubah Draft
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            ) : null}

                                            <hr/>
                                            <Row className="mb-3">
                                                <Col md="4">
                                                    <Link to="/outbox/daftarsurat.html">
                                                        <Button color="danger" type="button"><i className="uil-arrow-left"></i> Kembali</Button>
                                                    </Link>
                                                </Col>
                                                <Col md="8">
                                                    {! this.state.current_signer || this.state.current_signer.signer_order === 1 ? <>
                                                        <Button color="warning" className="waves-effect" type="button" onClick={() => this.handleSendBack()}>
                                                            <i className="uil-backspace"></i> Kembalikan
                                                        </Button>
                                                        {' '}

                                                        {this.state.atasan && ! this.state.show_sign ? <>
                                                            <Button color="info" className="waves-effect" type="button" onClick={() => this.handleSendReview()}>
                                                                <i className="uil-envelope-send"></i> Kirim ke Atasan
                                                            </Button>
                                                        </> : null}
                                                        {' '}
                                                    </> : null}

                                                    {
                                                        this.state.show_sign  ?
                                                        this.state.detail.letter_type_sign_type !== 0 ?
                                                        <Button color="primary"  className="waves-effect" type="button" onClick={() => this.handleOnSignClick()}>
                                                            <i className="uil-check-circle"></i> Tanda Tangan
                                                        </Button>
                                                        :
                                                        <Button color="primary"  className="waves-effect" type="button" onClick={() => this.handleSignNonDs()}>
                                                            <i className="uil-check-circle"></i> Tanda Tangan
                                                        </Button>
                                                    : null
                                                    }


                                                </Col>
                                            </Row>

                                        </Col>
                                        <Col md="7" style={{marginTop: '-16px'}}>
                                            { this.state.prevfile == "sharepoint" && this.state.IurlDoc ?

                                                // <iframe
                                                //     key={this.state.random}
                                                //     src={this.state.IurlDoc}
                                                //     width={"100%"}
                                                //     height={"880"}
                                                //     onLoad={this.hideLoad}
                                                //     frameBorder="10"
                                                //     marginHeight="0"
                                                //     marginWidth="0"
                                                //     >
                                                // </iframe>
                                                null
                                                : null
                                            }

                                            { this.state.detail ?
                                                <PreviewLetterOutPdf
                                                    isOpen={this.state.openprev}
                                                    path={this.state.detail.letter_out_draftpdf}
                                                    isNeedRegenerate={this.state.detail.letter_out_draftpdf_need_regen}
                                                    onReloadRegenerate={this.handleOnReloadRegenerate}
                                                    onAutoReloadRegenerate={this.handleOnAutoReloadRegenerate}
                                                    showLoading={this.state.showPreviewLoading}
                                                    timeout={this.state.autoReloadTimeout}
                                                />
                                                :   <span>Proses Menampilkan File</span>
                                            }
                                            {/* <PdfViewer isOpen={true} path={"SuratKeluar/DRAFT_21EO-SJPG7F.pdf"} minio={true} type={"preview"}/> */}
                                        </Col>
                                    </Row>

                                </CardBody>
                            </Card>
                            <LetterTimeline values={this.state.logsurat} />
                        </Col>
                    </Row>
                </Container>
                <Modal
                    size="lg"
                    isOpen={this.state.showReferenceLetter}
                    toggle={() => {
                        this.setState({
                            showReferenceLetter: this.state.showReferenceLetter
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myLargeModalLabel"
                        >
                            Informasi Surat Masuk
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showReferenceLetter: false
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                    { this.state.refQrcode != null ?
                        <DetailSuratMasuk reqQrode={this.state.refQrcode}/>
                        : null
                    }
                        {/* <TableActiveComponent changeQRCode={this.handleGetQRCode}/> */}
                    </div>
                    <div className="modal-footer">
                          <button
                            type="button"
                            onClick={() => {
                                this.setState({
                                    showReferenceLetter: false
                                })
                            }}
                            className="btn btn-secondary waves-effect"
                            data-dismiss="modal"
                          >
                            Tutup
                            </button>

                        </div>
                </Modal>
                <Modal
                    size="md"
                    isOpen={this.state.modalSendBack}
                    toggle={() => {
                        this.setState({
                            modalSendBack: this.state.modalSendBack
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myLargeModalLabelXXX"
                        >
                            Kembalikan Surat
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    modalSendBack: false
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Col md="12">
                            <fieldset className="mb-3">
                                <legend className="fs-6 fw-medium mb-2">
                                    Surat akan dikembalikan untuk direvisi ke:
                                </legend>

                                <div className="form-check form-check-end">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="tipe_penerima_revisi"
                                      value="drafter"
                                      id="penerima-revisi__drafter"
                                      checked={ ! this.state.isDelegateRevision }
                                      onChange={ this.handleRevisionReceiverTypeChange }
                                    />

                                    <label
                                      className="form-check-label"
                                      htmlFor="penerima-revisi__drafter"
                                    >
                                        Drafter
                                    </label>

                                    <p>
                                        Nama &emsp;: { this.state.drafter?.profil_name || ""}<br/>
                                        Jabatan : { this.state.drafter?.profil_position || ""}
                                    </p>
                                </div>

                                <div className="form-check form-check-end">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="tipe_penerima_revisi"
                                      value="delegasi"
                                      id="penerima-revisi__delegasi"
                                      checked={ this.state.isDelegateRevision }
                                      onChange={ this.handleRevisionReceiverTypeChange }
                                    />

                                    <label
                                      className="form-check-label mb-2"
                                      htmlFor="penerima-revisi__delegasi"
                                    >
                                        Tujuan Lain
                                    </label>
                                </div>

                                { this.state.isDelegateRevision ? <>
                                    <Select
                                      name="other_revision_receiver"
                                      placeholder="Pilih Tujuan Revisi Surat"
                                      options={ this.state.letterRevisionReceiverList }
                                      onChange={ this.handleOtherRevisionReceiverChange }
                                      styles={{
                                          control: (baseStyles, state) => ({
                                              ...baseStyles,
                                              borderColor:
                                                this.state.hideWarningOtherRevisionReceiver
                                                  ? baseStyles.borderColor
                                                  : 'rgb(var(--bs-danger-rgb))',
                                          }),
                                      }}
                                    />

                                    { ! this.state.hideWarningOtherRevisionReceiver ? <>
                                        <span className="text-danger">
                                            Pilih Tujuan Revisi Surat
                                        </span>
                                    </> : null }
                                </> : null }
                            </fieldset>
                        </Col>
                        <Col md="12">
                            <div className="mb-3">
                            <label >Catatan <span className="text-danger">*</span>
                            </label>
                            <Input
                             name="response_note"
                             placeholder="Isi Catatan.."
                             type="textarea"
                             errorMessage="Masukkan Catatan"
                             className="form-control"
                             validate={{ required: { value: true } }}
                             onChange={this.handleChangeNote}
                             id="response_note"
                             invalid={ ! this.state.hideWarningEmptyNote }
                            />

                                { ! this.state.hideWarningEmptyNote ? <>
                                    <span className="text-danger">
                                        Masukkan catatan
                                    </span>
                                </> : null }
                            </div>
                        </Col>
                    </div>
                    <div className="modal-footer">
                          <button
                            type="button"
                            onClick={() => {
                                this.setState({
                                    modalSendBack: false
                                })
                            }}
                            className="btn btn-danger waves-effect"
                            data-dismiss="modal"
                          >
                            Batal
                            </button>
                            <button
                            type="button"
                            onClick={() =>this.handleReturnLetter()}
                            className="btn btn-primary waves-effect"
                            data-dismiss="modal"
                          >
                            Kirim
                            </button>
                        </div>
                </Modal>

                <ModalSendReview
                    show={this.state.modalSendReview}

                    directReviewer={this.state.atasan}
                    onChooseDirectReviewer={this.handleChooseReviewDirect}

                    otherReviewerOptions={this.state.delegationOption}
                    onChooseOtherReviewer={this.handleChooseReviewDelegation}
                    onSelectedOtherReviewer={this.handleSetReviewer}

                    onChangedNote={(event) => {
                        this.setState({ review_note: event.target.value })
                    }}

                    onClose={() => this.setState({ modalSendReview: false })}
                    onSend={this.handleSendLetter}
                />

                <Modal
                    size="md"
                    isOpen={this.state.modalSignLetter}
                    toggle={() => {
                        this.setState({
                            modalSignLetter: this.state.modalSignLetter
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myLargeModalLabelzzz"
                        >
                            Tanda Tangan Elektronik
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    modalSignLetter: false
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Col md="12">
                            <div className="mb-1">
                            <label >Surat ini akan ditanda tangani untuk :
                            </label>
                            </div>
                        </Col>
                        <Col md="12">
                            <div className="mb-3">
                                <div className="form-check form-check-end">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="exampleRadiosSign"
                                        value="1"
                                        id="selfSign"
                                        onChange={() => this.handleChooseSigner(1)}
                                        defaultChecked={true}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="selfSign"
                                    >
                                        Diri Sendiri
                                    </label>
                                    {this.state.iseselon1 ?
                                    <Select
                                        name="letter_out_drafter_uuid"
                                        placeholder="Pilih Unit Konseptor Surat"
                                        options={this.state.konseptorOption}
                                        onChange={this.handleSetKonseptor}
                                    />
                                    : null
                                    }
                                </div>
                            </div>
                        </Col>

                        {! this.state.current_signer || this.state.current_signer.signer_order === 1 ? <>
                            <Col md="12">
                                <div className="mb-3">
                                    <div className="form-check form-check-end">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="exampleRadiosSign"
                                            value="2"
                                            id="behalfSign"
                                            onChange={() => this.handleChooseSigner(2)}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="behalfSign"
                                        >
                                            Atas Nama {this.state.atas_nama ? this.state.atas_nama.position_name : ""}
                                        </label>
                                    </div>
                                </div>
                            </Col>

                            {this.state.iseselon2 ? <>
                                <Col md="12">
                                    <div className="mb-3">
                                        <div className="form-check form-check-end">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="exampleRadiosSign"
                                                value="3"
                                                id="behalfSign3"
                                                onChange={() => this.handleChooseSigner(3)}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="behalfSign3"
                                            >
                                                u.b
                                            </label>
                                            {this.state.chooseub ? <>
                                                <Select
                                                    name="letter_out_eselonsatu_uuid"
                                                    placeholder="Pilih Eselon 1"
                                                    options={this.state.eselonsatuOption}
                                                    onChange={this.handleSetEselon1}
                                                />
                                            </> : null}
                                        </div>
                                    </div>
                                </Col>
                            </> : null}
                        </> : null}

                        {/* <Col md="12">
                            <div className="mb-3">
                            <label >Surat ini akan ditandatangani secara elektronik, Silahkan input Passphrase untuk melanjutkan <span className="text-danger">*</span>
                            </label>
                            <Input
                             name="passphrase"
                             placeholder="******"
                             type="password"
                             errorMessage="Masukkan Catatan"
                             className="form-control"
                             validate={{ required: { value: true } }}
                             //value={this.state.response_note}
                             onChange={this.handleChangePassphrase}
                             id="passphrase"
                            />
                            </div>
                        </Col> */}
                    </div>
                    <div className="modal-footer" style={{display:'block'}}>
                        {/* <img src={logoBsre} alt="" height="42" /> */}
                        <div className="float-end">
                            <button
                                type="button"
                                onClick={() => {
                                    this.setState({
                                        modalSignLetter: false
                                    })
                                }}
                                className="btn btn-danger waves-effect"
                                data-dismiss="modal"
                            >
                            Batal
                            </button>
                            {' '}
                            <button
                                type="button"
                                onClick={() =>this.handleSignLetter()}
                                className="btn btn-primary waves-effect"
                                data-dismiss="modal"
                            >
                            Tandatangan
                            </button>
                        </div>
                    </div>
                </Modal>
                <Modal
                    size="md"
                    isOpen={this.state.modalNotifSign}
                    toggle={() => {
                        this.setState({
                            modalNotifSign: this.state.modalNotifSign
                        })
                    }}
                    centered={true}
                >

                    <div className="modal-body">
                        <Col md="12">
                            <div className="mb-3" style={{textAlign:"center"}}>
                            <i className="uil uil-exclamation-octagon display-5 mt-2 mb-3 text-danger"></i>
                            <h5>GAGAL</h5>
                            <label >{this.state.failedInfo}.
                            {/* <br/>Apakah anda ingin menandatangani Surat ini tanpa <b>TTE</b>? */}
                            <br/>Klik Kirim untuk mencoba Lagi.
                            </label>
                            </div>
                        </Col>
                    </div>
                    <div className="modal-footer" style={{display:'block'}}>
                        <div className="float-end">
                            <button
                                type="button"
                                onClick={() => {
                                    this.setState({
                                        modalNotifSign: false
                                    })
                                }}
                                className="btn btn-danger waves-effect"
                                data-dismiss="modal"
                            >
                            Batal
                            </button>
                            {' '}
                            <button
                                type="button"
                                //onClick={() =>this.handleSignNoDS()}
                                onClick={() =>this.handleSignLetter()}
                                className="btn btn-primary waves-effect"
                                data-dismiss="modal"
                            >
                            Kirim
                            </button>
                        </div>
                    </div>
                </Modal>

                <ModalCannotSign
                    isOpen={this.state.showModalCannotSign}
                    onClose={this.handleHideCannotSignMessage}
                    message={this.state.cannot_sign_message}
                />

                <Modal
                    size="lg"
                    isOpen={this.state.modalAddAttachment}
                    toggle={() => {
                        this.setState({
                            modalAddAttachment: this.state.modalAddAttachment
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myLargeModalLabelzzzXX"
                        >
                            Tambah Lampiran
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    modalAddAttachment: false
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <AvForm
                        className="needs-validation"
                        onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                    >
                    <div className="modal-body">
                            <Col md="12">
                                <div className="mb-3">
                                <FileInput
                                    name="document"
                                    //validate={{ required: { value: true } }}
                                    isMultiple
                                />
                                </div>
                            </Col>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            onClick={() => {
                                this.setState({
                                    modalAddAttachment: false
                                })
                            }}
                            className="btn btn-danger waves-effect"
                            data-dismiss="modal"
                          >
                            Batal
                        </button>
                        <Button color="success" type="submit">
                            <i className="uil-arrow-up-right"></i> Simpan Lampiran
                        </Button>
                    </div>
                    </AvForm>
                </Modal>
                <ConfirmAlert
                    confirmTitle="Konfirmasi!"
                    confirmInfo={this.state.confirmInfo}

                    loadingTitle={this.state.loadingTitle}
                    loadingInfo={this.state.loadingInfo}

                    successTitle="Berhasil!"
                    successInfo={this.state.successInfo}

                    failedTitle="Gagal!"
                    //failedInfo="Data gagal disimpan"
                    failedInfo={this.state.failedInfo}

                    showConfirm={this.state.confirm}
                    showLoading={this.state.loading}
                    showSuccess={this.state.success}
                    showFailed={this.state.failed}
                    response={this.confirmResponse}
                />
                { this.state.redirect ?
                <Redirect to={this.state.redirecturl}/>
                //null
                    // this.state.submitStatus == 1 ?
                    // <Redirect to='/inbox/daftarterkirim.html'/>
                    // :
                    // <Redirect to='/inbox/daftardraft.html'/>
                : null }
            </Fragment>
        )
    }
}

//export default IndexView
export default withRouter(DetailView);
